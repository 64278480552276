import gsap from 'gsap'
import { mdBreakpoint } from '~/constants'

export const headingStartAnimation = (trigger: string): void => {
    if (window.innerWidth < mdBreakpoint) { return }
    gsap.timeline({
        scrollTrigger: { trigger, start: 'top 80%', end: 'bottom 40%', scrub: true }
    }).from(trigger, { x: 500, opacity: 0, duration: 2 })
}

export const contentStartAnimation = (triggers: string[]) => {
    if (window.innerWidth < mdBreakpoint) { return }
    triggers.forEach(trigger => gsap.timeline({
        scrollTrigger: { trigger, start: 'top 90%', end: 'top 50%', scrub: true }
    }).from(trigger, 1.8, { opacity: 0, y: 100, ease: 'power4.out', skewY: 7, stagger: { amount: 0.3 } }))
}

export const fromOpacityAnimation = (selector: string, length: number) => {
    if (window.innerWidth < mdBreakpoint) { return }
    for (let i = 0; i <= length; i++) {
        const trigger = `${selector}-${i}`
        gsap.timeline({
            scrollTrigger: { trigger, start: 'top bottom', end: 'bottom 40%', scrub: true }
        }).from(trigger, { opacity: 0 }).to(trigger, { opacity: 1 })
    }
}

export const linesStartAnimation = (selector: string, length: number, scrub: boolean = false) => {
    if (window.innerWidth < mdBreakpoint) { return }
    for (let i = 0; i <= length; i++) {
        const trigger = `${selector}-${i}`
        gsap.timeline({
            scrollTrigger: { trigger, start: 'top 90%', end: 'bottom 70%', scrub }
        }).from(trigger, { x: 500, opacity: 0 }).to(trigger, { x: 0, opacity: 1 })
    }
}

export const photoStartAnimation = (selector: string, length: number) => {
    if (window.innerWidth < mdBreakpoint) { return }
    for (let i = 0; i <= length; i++) {
        const trigger = `${selector}-${i}`

        gsap.timeline({
            scrollTrigger: { trigger, start: 'top 65%', scrub: true }
        }).from(trigger, { y: -300, opacity: 0 }).to(trigger, { y: 0, opacity: 1 })
    }
}
