<template>
    <section id="contact" class="the-why-section container section">
        <div class="section-number-heading the-why-section__section-number-heading">
            <span>03</span>
            <h2>And ... the where</h2>
        </div>

        <div class="the-why-section__content">
            <div class="the-why-section__text">
                <h3 v-if="sectionData.title" class="the-why-section__title">
                    {{ sectionData.title }}
                </h3>
                <p v-if="sectionData.description" class="the-why-section__description">
                    {{ sectionData.description }}
                </p>

                <a v-if="sectionData.email" :href="`mailto:${sectionData.email}`" class="the-why-section__link" data-cta="Email">
                    {{ sectionData.email }}
                </a>
                <a v-if="sectionData.phone" :href="`tel:${sectionData.phone}`" class="the-why-section__link" data-cta="Phone">
                    {{ sectionData.phone }}
                </a>
                <a href="tel:+385 98 184 31 27" class="the-why-section__link" data-cta="Phone">
                    +385 98 184 31 27
                </a>
            </div>

            <div class="the-why-section__form">
                <div v-if="isLoading" class="the-why-section__is-loading" />
                <h2 v-else-if="sendSuccess" class="the-why-section__success">
                    Thank you! We will contact you as soon as we read the message.
                </h2>
                <h2 v-else-if="sendError" class="the-why-section__error">
                    Something went wrong. <br> Try again later
                </h2>
                <FormKit
                    v-else
                    id="contactForm"
                    v-model="formData"
                    type="form"
                    :actions="false"
                    validation-visibility="dirty"
                    form-class="form-wrapper"
                    autocomplete="do-not-autocomplete"
                >
                    <FormKitSchema :schema="contactFormSchema" :data="formData" />
                    <div class="the-why-section__submit-wrapper">
                        <nuxt-link v-on:click.native="submitForm" class="the-how-section__project-link-welcome">
                            <span>Submit</span>
                            <img :src="'/img/icons/arrow-right.svg'" alt="Link arrow">
                        </nuxt-link>
                    </div>
                </FormKit>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from '#imports'
import CircleButton from '~/components/ui/CircleButton.vue'
import { CIRCLE_BUTTON_ARROWS } from '~/types/buttons'
import { contactFormSchema } from '~/constants/schemas'
import { headingStartAnimation, contentStartAnimation } from '~/utils/gsap'
import axios from 'axios'
import { useRuntimeConfig } from '#app'

const config = useRuntimeConfig()

const sectionData = ref({
    title: 'You know where.',
    description: 'Right here! In both UK and Croatia. Edinburgh and Zagreb. So come on, reach out. Let create some magic together.',
    email: 'hello@spellcaster.agency',
    phone: '+44 777 481 50 42'
})

const buttonData = ref({
    title: 'submit',
    arrow: CIRCLE_BUTTON_ARROWS.RIGHT
})

const formData = ref({
    name: '',
    company: '',
    email: '',
    budget: '',
    content: '',
    contact_type: 'contact'
})

const sendSuccess = ref(false)
const sendError = ref(false)
const isLoading = ref(false)

const submitForm = (e:Event) => {
    try {
        e.preventDefault()
        isLoading.value = true
          axios
             .post(
                config.public.MAIL_URL,
                formData.value,
                 {
                    headers:{
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                 }
             )
             .then(res => {
                sendSuccess.value = true
             });
    } catch (e) {
        sendError.value = true
    } finally {
        isLoading.value = false
    }
}

onMounted(() => nextTick(() => setTimeout(() => {
    headingStartAnimation('.the-why-section__section-number-heading')
    contentStartAnimation(['.the-why-section__content'])
}, 500)))
</script>
