export const contactFormSchema = [
    {
        $el: 'div',
        attrs: {
            class: 'the-why-section__input-wrapper'
        },
        children: [
            {
                $formkit: 'text',
                label: '',
                placeholder: 'Name',
                name: 'name',
                validation: 'required'
            },
            {
                $formkit: 'text',
                label: '',
                placeholder: 'Company',
                name: 'company',
                validation: 'required'
            }
        ]
    },
    {
        $el: 'div',
        attrs: {
            class: 'the-why-section__input-wrapper'
        },
        children: [
            {
                $formkit: 'text',
                label: '',
                placeholder: 'E-mail',
                name: 'email',
                validation: 'email|required'
            },
            {
                $formkit: 'text',
                label: '',
                placeholder: 'Budget',
                name: 'budget',
                validation: 'required'
            }
        ]
    },
    {
        $formkit: 'textarea',
        label: '',
        placeholder: 'Message',
        name: 'content',
        validation: 'required'
    }
]
